/* Dropdowns */

.dropdown,
.btn-group {
  .dropdown-toggle {
    &:after {
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-bottom: 0;
      font: normal normal normal 24px/1 "feather";
      content: "\e842";
      width: auto;
      height: auto;
      vertical-align: middle;
      line-height: .625rem;
      font-size: .875rem;
      margin-left: .3rem;
    }
  }
  .dropdown-menu {
    padding: .35rem;
    box-shadow: $dropdown-box-shadow;
    border-radius: 0;
    .dropdown-item {
      font-size: .812rem;
      padding: .25rem .875rem;
      transition: all .2s ease-in-out;
      border-radius: 2px;
      cursor: pointer;
      i, svg {
        color: $text-muted;
        transition: all .2s ease-in-out;
      }
      &:hover {
        background-color: rgba(theme-color(primary), .1);
        &, i, svg {
          color: theme-color(primary);
        }
      }
      &:active {
        background: initial;
      }
    }
  }
}

.rtl {
  .dropdown,
  .btn-group {
    .dropdown-toggle {
      &::after {
        margin-left: 0;
        margin-right: .3rem;
      }
    }
  }
  .dropdown-menu {
    text-align: right;
  }
}
